<div class="class-post-container-wrapper" *ngIf="width >= 760">
  <ng-container *ngIf="isLoading; else detail">
      <app-spinner></app-spinner>
  </ng-container>

  <ng-template #detail>
      <ng-container *ngIf="isCourseActivity else classActivity">
          <div class="class-post-container bordered-box" *ngIf="courseActivity">
              <div>
                  <app-post-detail [isCourseActivity]="true" [post]='courseActivity' [text]="text" (onPreview)="previewCourseActivity($event)"></app-post-detail>
              </div>

              <div class="attachment">
                  <div class="content">
                      <div class="header">
                          <div class="text">{{courseActivity.files.length}} Attachments</div>
                          <!-- <div class="download">DOWNLOAD ALL</div> -->
                      </div>
                      <app-view-attachment [files]="courseActivity.files" [urls]="urls1" [type]="text"></app-view-attachment>
                  </div>
                  <ng-container *ngIf="text.toLowerCase() !== 'material'">
                      <ng-container *ngIf="!isActivitySubmitted">
                          <div class="wrapper">
                              <div class="txt">Submit Assignment</div>

                              <div class="submit">
                                  <div class="upload">
                                      <app-doc-upload [text]="'UPLOAD ASSIGNMENT'" [isdocUploaded]="isdocUploaded" (docUrl)="onDocUpload($event)"></app-doc-upload>
                                  </div>
                                  <div class="btn">
                                      <button mat-flat-button color="primary" [ngClass]="{'disabled' : !docUrl}" [disabled]="!isdocUploaded" (click)="submitCourseActivity()">SUBMIT</button>
                                  </div>
                              </div>
                          </div>
                      </ng-container>

                      <div class="review-assigment">
                          <ng-container *ngIf="isActivityEvaluated">
                              <div class="heading">
                                  <div class="text">My Submitted Assignment</div>
                                  <div class="download" (click)="previewActivityEvaluation()">VIEW EVALUATION</div>
                              </div>
                              <div class="subheader">
                                  <div class="status">
                                      <div class="label">Status:</div>
                                      <div class="review">Reviewed</div>
                                  </div>
                                  <div class="marks">
                                      <div class="label">Marks Obtained </div>
                                      <div class="value">{{evaluatedCourseActivity.marksObtained}}</div>
                                  </div>
                                  <div class="total">
                                      <div class="label">Total Marks</div>
                                      <div class="value">{{courseActivity.totalMarks}}</div>
                                  </div>
                                  <div class="total">
                                      <div class="label">Date Reviewed</div>
                                      <div class="value">{{evaluatedCourseActivity.submissionDate | date: formatDate}}</div>
                                  </div>
                              </div>
                          </ng-container>

                          <ng-container *ngIf="isActivitySubmitted && !isActivityEvaluated">
                              <div class="heading">
                                  <div class="text">My Submitted Assignment</div>
                              </div>
                              <div class="subheader">
                                  <div class="status">
                                      <div class="label">Status:</div>
                                      <div class="review">Submitted</div>
                                  </div>
                                  <div class="marks">
                                      <div class="label">Submitted Date:</div>
                                      <div class="value">{{submittedCourseActivity.submissionDate | date: formatDate}}</div>
                                  </div>
                              </div>
                          </ng-container>

                          <ng-container *ngIf="isActivityEvaluated">
                              <app-view-attachment [files]="evaluatedCourseActivity && evaluatedCourseActivity.files" [isEvaluated]="'true'" [metadata]="evaluatedActivityMetaData" [type]="text" [urls]="urls"></app-view-attachment>
                          </ng-container>

                          <ng-container *ngIf="!isActivityEvaluated && isActivitySubmitted">
                              <app-view-attachment [files]="submittedCourseActivity.files" [urls]="urls" [type]="text"></app-view-attachment>
                          </ng-container>
                      </div>
                  </ng-container>
              </div>
          </div>
      </ng-container>
      <ng-template #classActivity>
          <div class="class-post-container bordered-box">
              <div>
                  <app-post-detail [post]='post' [text]="text" (onPreview)="previewPost($event)"></app-post-detail>
              </div>

              <div class="attachment">
                  <div class="content">
                      <div class="header">
                          <div class="text">{{post.fileCount}} Attachments</div>
                          <!-- <div class="download">DOWNLOAD ALL</div> -->
                      </div>
                      <app-view-attachment [files]="post.files" [urls]="urls1" [type]="text"></app-view-attachment>
                  </div>
                  <ng-container *ngIf="text.toLowerCase() !== 'material'">
                      <ng-container *ngIf="!isPostSubmitted">
                          <div class="wrapper">
                              <div class="txt">Submit Assignment</div>

                              <div class="submit">
                                  <div class="upload">
                                      <app-doc-upload [text]="'UPLOAD ASSIGNMENT'" [isdocUploaded]="isdocUploaded" (docUrl)="onDocUpload($event)"></app-doc-upload>
                                  </div>
                                  <div class="btn">
                                      <button mat-flat-button color="primary" [ngClass]="{'disabled' : !docUrl}" [disabled]="!isdocUploaded" (click)="submit()">SUBMIT</button>
                                  </div>
                              </div>
                          </div>
                      </ng-container>

                      <div class="review-assigment">
                          <ng-container *ngIf="isPostEvaluated">
                              <div class="heading">
                                  <div class="text">My Submitted Assignment</div>
                                  <div class="download" (click)="previewClassPostEvaluation()">VIEW EVALUATION</div>
                              </div>
                              <div class="subheader">
                                  <div class="status">
                                      <div class="label">Status:</div>
                                      <div class="review">Reviewed</div>
                                  </div>
                                  <div class="marks">
                                      <div class="label">Marks Obtained </div>
                                      <div class="value">{{evaluatedAssignment.marks}}</div>
                                  </div>
                                  <div class="total">
                                      <div class="label">Total Marks</div>
                                      <div class="value">{{post.totalMarks}}</div>
                                  </div>
                                  <div class="total">
                                      <div class="label">Date Reviewed</div>
                                      <div class="value">{{evaluatedAssignment.submissionDate | date: formatDate}}</div>
                                  </div>
                              </div>
                          </ng-container>

                          <ng-container *ngIf="isPostSubmitted && !isPostEvaluated">
                              <div class="heading">
                                  <div class="text">My Submitted Assignment</div>
                              </div>
                              <div class="subheader">
                                  <div class="status">
                                      <div class="label">Status:</div>
                                      <div class="review">Submitted</div>
                                  </div>
                                  <div class="marks">
                                      <div class="label">Submitted Date:</div>
                                      <div class="value">{{post.submissionDate | date: formatDate}}</div>
                                  </div>
                              </div>
                          </ng-container>

                          <ng-container *ngIf="isPostEvaluated">
                              <app-view-attachment [files]="evaluatedAssignment.evalFiles" [isEvaluated]="'true'" [metadata]="evaluatedMetaData" [type]="text" [urls]="urls"></app-view-attachment>
                          </ng-container>

                          <ng-container *ngIf="!isPostEvaluated && isPostSubmitted">
                              <app-view-attachment [files]="submittedAssingment.files" [urls]="urls" [type]="text"></app-view-attachment>
                          </ng-container>
                      </div>
                  </ng-container>

              </div>
          </div>
      </ng-template>
  </ng-template>
</div>

<div class="class-post-container-wrapper" *ngIf="width < 760">
  <ng-container *ngIf="isLoading; else detail">
    <app-spinner></app-spinner>
  </ng-container>

  <ng-template #detail>
    <ng-container *ngIf="isCourseActivity else classActivity">
      <div class="class-post-container bordered-box" *ngIf="courseActivity">
        <div class="header" [class.mat-elevation-z8]="true">
          <div class="img" (click)="back()"> <img [src]=getBackIcon() /> </div>
          <div class="text">{{text | titlecase}}</div>
        </div>
        <div class="post-container">
          <div>
            <app-post-detail [isCourseActivity]="true" [post]='courseActivity' [text]="text" (onPreview)="previewCourseActivity($event)"></app-post-detail>
          </div>

          <div class="attachment">
            <div class="content">
              <div class="header">
                <div class="attachments-text"> Attachments ({{courseActivity.files.length}})</div>
                <!-- <div class="download">DOWNLOAD ALL</div> -->
              </div>
              <app-view-attachment [files]="courseActivity.files" [urls]="urls1" [type]="text"></app-view-attachment>
            </div>
          </div>
        </div>
        <div class="submit-container" *ngIf="text.toLowerCase() !== 'material'">
          <ng-container *ngIf="text.toLowerCase() !== 'material'">
            <ng-container *ngIf="!isActivitySubmitted">
              <div class="wrapper">
                <div class="txt">Submit Assignment</div>

                <div class="submit">
                  <div class="upload">
                    <app-doc-upload [text]="'UPLOAD ASSIGNMENT'" [isdocUploaded]="isdocUploaded" (docUrl)="onDocUpload($event)"></app-doc-upload>
                  </div>
                  <div class="btn">
                    <button mat-flat-button color="primary" [ngClass]="{'disabled' : !docUrl}" [disabled]="!isdocUploaded" (click)="submitCourseActivity()">SUBMIT</button>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="review-assigment">
              <ng-container *ngIf="isActivityEvaluated">
                <div class="heading">
                  <div class="text">My Submitted Assignment</div>
                  <div class="download" (click)="previewActivityEvaluation()">VIEW EVALUATION</div>
                </div>
                <div class="subheader">
                  <div class="status">
                    <div class="label">Status:</div>
                    <div class="review">Reviewed</div>
                  </div>
                  <div class="marks">
                    <div class="label">Marks Obtained </div>
                    <div class="value">{{evaluatedCourseActivity.marksObtained}}</div>
                  </div>
                  <div class="total">
                    <div class="label">Total Marks</div>
                    <div class="value">{{courseActivity.totalMarks}}</div>
                  </div>
                  <div class="total">
                    <div class="label">Date Reviewed</div>
                    <div class="value">{{evaluatedCourseActivity.submissionDate | date: formatDate}}</div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="isActivitySubmitted && !isActivityEvaluated">
                <div class="heading">
                  <div class="text">My Submitted Assignment</div>
                </div>
                <div class="subheader">
                  <div class="status">
                    <div class="label">Status:</div>
                    <div class="review">Submitted</div>
                  </div>
                  <div class="marks">
                    <div class="label">Submitted Date:</div>
                    <div class="value">{{submittedCourseActivity.submissionDate | date: formatDate}}</div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="isActivityEvaluated">
                <app-view-attachment [files]="evaluatedCourseActivity && evaluatedCourseActivity.files" [isEvaluated]="'true'" [metadata]="evaluatedActivityMetaData" [type]="text" [urls]="urls"></app-view-attachment>
              </ng-container>

              <ng-container *ngIf="!isActivityEvaluated && isActivitySubmitted">
                <app-view-attachment [files]="submittedCourseActivity.files" [urls]="urls" [type]="text"></app-view-attachment>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-template #classActivity>
      <div class="class-post-container bordered-box">
        <div class="header" [class.mat-elevation-z8]="true">
          <div class="img" (click)="back()"> <img [src]=getBackIcon() /> </div>
          <div class="text">{{text | titlecase}}</div>
        </div>
        <div class="post-container">
          <div>
            <app-post-detail [post]='post' [text]="text" (onPreview)="previewPost($event)"></app-post-detail>
          </div>

          <div class="attachment">
            <div class="content">
              <div class="header">
                <div class="attachments-text"> Attachments ({{post.fileCount}})</div>
                <!-- <div class="download">DOWNLOAD ALL</div> -->
              </div>
              <app-view-attachment [files]="post.files" [urls]="urls1" [type]="text"></app-view-attachment>
            </div>
          </div>
        </div>
        <div class="submit-container" *ngIf="text.toLowerCase() !== 'material'">
          <ng-container *ngIf="text.toLowerCase() !== 'material'">
            <ng-container *ngIf="!isPostSubmitted">
              <div class="wrapper">
                <div class="txt">Submit Assignment</div>

                <div class="submit">
                  <div class="upload">
                    <app-doc-upload [text]="'UPLOAD ASSIGNMENT'" [isdocUploaded]="isdocUploaded" (docUrl)="onDocUpload($event)"></app-doc-upload>
                  </div>
                  <div class="btn">
                    <button mat-flat-button color="primary" [ngClass]="{'disabled' : !docUrl}" [disabled]="!isdocUploaded" (click)="submit()">SUBMIT</button>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="review-assigment">
              <ng-container *ngIf="isPostEvaluated">
                <div class="heading">
                  <div class="text">My Submitted Assignment</div>
                  <div class="download" (click)="previewClassPostEvaluation()">VIEW EVALUATION</div>
                </div>
                <div class="subheader">
                  <div class="status">
                    <div class="label">Status:</div>
                    <div class="review">Reviewed</div>
                  </div>
                  <div class="marks">
                    <div class="label">Marks Obtained </div>
                    <div class="value">{{evaluatedAssignment.marks}}</div>
                  </div>
                  <div class="total">
                    <div class="label">Total Marks</div>
                    <div class="value">{{post.totalMarks}}</div>
                  </div>
                  <div class="total">
                    <div class="label">Date Reviewed</div>
                    <div class="value">{{evaluatedAssignment.submissionDate | date: formatDate}}</div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="isPostSubmitted && !isPostEvaluated">
                <div class="heading">
                  <div class="text">My Submitted Assignment</div>
                </div>
                <div class="subheader">
                  <div class="marks">
                    <div class="label">Submitted Date:</div>
                    <div class="value">{{post.submissionDate | date: formatDate}}</div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="isPostEvaluated">
                <app-view-attachment [files]="evaluatedAssignment.evalFiles" [isEvaluated]="'true'" [metadata]="evaluatedMetaData" [type]="text" [urls]="urls"></app-view-attachment>
              </ng-container>

              <ng-container *ngIf="!isPostEvaluated && isPostSubmitted">
                <app-view-attachment [files]="submittedAssingment.files" [urls]="urls" [type]="text"></app-view-attachment>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
