import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { CountdownComponent } from 'ngx-countdown';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StudentTestService } from '../../services/student-test.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { QuestionModel } from 'src/app/models/test-questions.models';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { StudentCourseActivitiesService } from '../services/student-course-activities.service';
import { COURSE_ACTIVITY_ROUTE, SUBMITTED_STATUS } from 'src/app/Constants/Constant';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-take-test',
  templateUrl: './take-test.component.html',
  styleUrls: ['./take-test.component.scss']
})
export class TakeTestComponent implements OnInit, OnDestroy {
  width = window.innerWidth;
  test: QuestionModel;
  testID: string;
  totalTime: string;
  leftTime: number;
  hoursLeft: number;
  minsLeft: number;
  testStarted = false;
  questionList = [];
  questionDetail;
  quesClick = true;
  isSubmitted = false;
  isLoading = true;
  instruction: string;
  isCourseActivity = false;
  studentID: string;

  private destroy$$: Subject<any> = new Subject<any>();

  constructor(
    public location: Location,
    public sidenav: ToggleSidenavService,
    public snackbar: SnackbarService,
    public dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    public testService: StudentTestService,
    public localstorage: LocalStorageService,
    private courseActivitiesSvc: StudentCourseActivitiesService,
  ) { }

  ngOnInit(): void {

    window.addEventListener('click', function(e){
      console.log(this);
      let flagRound = false;
      let flagQuestion = false;
      const round = document.getElementsByClassName('round');
      const question = document.getElementsByClassName('question');
      for (let index = 0; index < round.length; index++) {
        if (e.target === round[index]) {
          flagRound = true;
          break;
        }
      }
      if (flagRound === false) {
        for (let index = 0; index < question.length; index++) {
          if (e.target === question[index]) {
            flagQuestion = true;
            break;
          }
        }
      }
      if (flagRound || flagQuestion) {

      }
    });

    this.sidenav.minmizeSidenav();
    this.testID = this.route.snapshot.params.id;
    this.studentID = this.route.parent.snapshot.params.id;

    this.isCourseActivity = this.router.url.includes(COURSE_ACTIVITY_ROUTE);

    if (this.isCourseActivity) {
      this.courseActivitiesSvc.getCourseActivity(this.testID, this.studentID);

      this.courseActivitiesSvc.selectedCourseActivity$.pipe(
        takeUntil(this.destroy$$)
      ).subscribe((activityDetails) => {
        if (activityDetails) {
          this.test = activityDetails.activity;
          if (activityDetails.submission.status === SUBMITTED_STATUS) {
            this.router.navigate(['result'], { relativeTo: this.route, replaceUrl: true });
            return;
          }
          this.testService.getQuestionFromGcs(activityDetails.activityURLs[0]).pipe(
            takeUntil(this.destroy$$)
          ).subscribe((res) => {
            res = JSON.parse(res);
            res.map(question => {
              if (question.instruction) {
                this.instruction = question.instruction;
                return;
              }
              question.options.map(option => {
                option.isSelected = false;
              });
              this.questionList.push(question);
            });
            this.isLoading = false;
          },
            (err) => {
              this.isLoading = false;
              this.snackbar.openSnackbar(`Unable to get the test details!! ${err.error.error}`);
            });
        }
      });
    } else {
      this.testService.getTest(this.testID).pipe(
        switchMap((ques) => {
          this.totalTime = new Date(ques.test.timings).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}).slice(12, 17);
          this.minsLeft = parseInt(this.totalTime.slice(3, 5), 10);
          this.hoursLeft = parseInt(this.totalTime.slice(0, 2), 10);
          if (this.hoursLeft >= 6)  {
            this.hoursLeft = 12 - this.hoursLeft;
          }
          this.leftTime = (this.minsLeft * 60) + (this.hoursLeft * 3600);
          this.test = ques.test;
          return this.testService.getQuestionFromGcs(ques.questionURL);
        })
      )
        .subscribe(
          (res: any) => {
            res = JSON.parse(res);
            console.log(res);
            res.map(question => {
              if (question.instruction) {
                this.instruction = question.instruction;
                return;
              }
              question.options.map(option => {
                option.isSelected = false;
              });
              this.questionList.push(question);
            });
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            this.snackbar.openSnackbar(`Unable to get the test details!! ${err.error.error}`);
          }
        );
    }
  }

  noQues() {
    this.questionDetail = null;
  }
  goToQuestion(type: 'previous' | 'next') {
    const index = this.questionDetail[0].number;
    if (type === 'previous') {
      if (index === 0) {
        return;
      }
      this.questionDetail = [];
      this.questionDetail.push(this.questionList[index - 1]);
    } else if (type === 'next') {
      if ((index + 1) === this.questionList.length) {
        return;
      }
      this.questionDetail = [];
      this.questionDetail.push(this.questionList[index + 1]);
    } else {
      return;
    }
  }

  getQuestion(question) {
    this.questionDetail = [];
    this.questionDetail.push(question);
    console.log(this.questionList);
    console.log(this.questionDetail);
  }

  back() {
    this.sidenav.maximizeSidenav();
    this.location.back();
  }
  getBackIcon() {
    return '../../../assets/green-back.svg';
  }

  mapAnswers() {
    const mapped_ans = [];
    this.questionList.map(question => {
      const selectedOptions = [];
      const correctOptions = [];
      question.options.filter((option, index) => {
        if (option.isSelected) {
          selectedOptions.push(index);
        }
      });
      question.options.filter((option, index) => {
        if (option.isCorrect) {
          correctOptions.push(index);
        }
      });
      mapped_ans.push({ questionNumber: question.number, selectedAnswers: selectedOptions, correctAnswers: correctOptions, marks: parseInt(question.marks.toString()) });
    });
    return mapped_ans;
  }

  submitTest() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: '363px',
        height: '190px',
        backdropClass: 'blur',
        data: {theme: 'student', isUpload: true, alertText: 'Are you sure, you want to submit the test'},
        panelClass: ['delete-dialog-css'],
        autoFocus: false
      });

    dialogRef.afterClosed().subscribe((isDeleted: boolean) => {
      if (!!isDeleted) {
        this.isLoading = true;
        const mappedAnswers = this.mapAnswers();
        if (!this.isCourseActivity) {
          const requestBody = {
            testID: this.testID,
            studentID: this.localstorage.getItem('studentID'),
            answers: mappedAnswers
          };
          this.testService.submitTest(requestBody).subscribe(
            (res) => {
              this.isLoading = false;
              this.isSubmitted = true;
              this.snackbar.openSnackbar('Congratulations!!! You have successfully completed the test!!');
            },
            (err) => {
              this.isLoading = false;
              this.snackbar.openSnackbar(`Unable to submit the test!! ${err.error.error}`);
            }
          );
        } else {
          const requestBody = {
            activityID: this.testID,
            studentID: this.localstorage.getItem('studentID'),
            answers: JSON.stringify(mappedAnswers)
          };
          this.courseActivitiesSvc.submitCourseActivity(requestBody).subscribe(
            (res) => {
              this.isLoading = false;
              this.isSubmitted = true;
              this.snackbar.openSnackbar('Congratulations!!! You have successfully completed the test!!');
            },
            (err) => {
              this.isLoading = false;
              this.snackbar.openSnackbar(`Unable to submit the test!! ${err.error.error}`);
            }
          );
        }
      }
    });
  }

  timeUp(e: Event) {
    if (e['action'] === 'done') {
      console.log('in timeup');
      this.isLoading = true;
      const mappedAnswers = this.mapAnswers();
      if (!this.isCourseActivity) {
        const requestBody = {
          testID: this.testID,
          studentID: this.localstorage.getItem('studentID'),
          answers: mappedAnswers
        };
        this.testService.submitTest(requestBody).subscribe(
          (res) => {
            this.isLoading = false;
            this.isSubmitted = true;
            this.snackbar.openSnackbar('TIME UP!!! Your test has been submitted!!');
          },
          (err) => {
            this.isLoading = false;
            this.snackbar.openSnackbar(`Unable to submit the test!! ${err.error.error}`);
          }
        );
      } else {
        const requestBody = {
          activityID: this.testID,
          studentID: this.localstorage.getItem('studentID'),
          answers: JSON.stringify(mappedAnswers)
        };
        this.courseActivitiesSvc.submitCourseActivity(requestBody).subscribe(
          (res) => {
            this.isLoading = false;
            this.isSubmitted = true;
            this.snackbar.openSnackbar('TIME UP!!! Your test has been submitted!!');
          },
          (err) => {
            this.isLoading = false;
            this.snackbar.openSnackbar(`Unable to submit the test!! ${err.error.error}`);
          }
        );
      }
    }
  }

  getResult() {
    this.router.navigate(['result'], { relativeTo: this.route });
  }

  start() {
    this.testStarted = true;
  }

  ngOnDestroy() {
    this.courseActivitiesSvc.reset();
    this.sidenav.maximizeSidenav();
  }
}
