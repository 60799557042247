<div class="test-evaluate-container" *ngIf="width>=760">
<ng-container *ngIf="isLoading; else testEvaluation">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #testEvaluation>
    <div class="evaluate-wrapper">
        <div class="header">
            <div class="name">
                <div class="back" (click)="back()"> <img [src]="backButtonSrc" /> </div>
                <div>{{studentTest.studentName | titlecase}}</div>
            </div>
            <div class="right">
                <div class="txt">
                    <div>CORRECT ANSWERS</div>
                    <div class="correct">{{studentTest.correctAnswers}}</div>
                </div>
                <div class="txt">
                    <div>INCORRECT ANSWERS</div>
                    <div class="incorrect">{{studentTest.incorrectAnswers}}</div>
                </div>
                <div class="txt">
                    <div>MARKS OBTAINED</div>
                    <div class="marks">{{studentTest.marks}} Marks</div>
                </div>

                <div class="action" [ngClass]="theme">
                    <button mat-icon-button (click)="select.open()">
                    <mat-label>ACTIONS</mat-label>
                    <mat-select
                        #select
                        [formControl]="filter"
                    >
                        <mat-option *ngFor="let action of actions"  [value]="action">{{action}}</mat-option>
                    </mat-select>
                </button>
                </div>

            </div>
        </div>

        <div class="main">
            <ng-container *ngIf="!!filter.value">
                <div class="filter">
                    <div class="text">Showing:</div>
                    <div class="val">{{filter.value}}</div>
                </div>
            </ng-container>

            <ng-container *ngIf="!filteredList.length">
                <div class="no-result">No results Found</div>
            </ng-container>

            <div *ngFor="let ques of filteredList; let i= index" class="questionList">
                <div [ngClass]="{'questionaire': !(ques.answer[0].selectedAnswers && ques.answer[0].selectedAnswers.length)}">
                    <div class="ques-header">
                        <div class="index">
                            <div>Question #{{i+1}}</div>
                            <div class="img" *ngIf="(ques.answer[0].selectedAnswers && ques.answer[0].selectedAnswers.length)"> <img [src]="getAnswerStatus(ques) ? './../../assets/info-mark.svg' : './../../assets/red-cross.svg'" /> </div>
                        </div>
                        <div>{{ques.answer[0].marks}} marks</div>
                    </div>

                    <div class="name">
                        <div>{{ques.question.question}}</div>
                    </div>

                    <div class="image" *ngIf="ques.question.image">
                        <img [src]=ques.question.image />
                    </div>

                    <div *ngFor="let option of ques.question.options; let i = index" class="option">
                        <div class="image" *ngIf="option.image">
                            <img [src]=option.image />
                        </div>
                        <div class="selected-option">
                            <div class="correct-option" [ngClass]="{'correct': option.isCorrect}">{{option.option}}</div>
                            <div class="attempt" *ngIf="isAnswerCorrect(ques.answer[0], i)"></div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="theme === 'teacher'; else student">
                    <ng-container *ngIf="ques.answer[0].comments !== ''; else addComment">
                        <div class="comment">
                            <div class="val">{{ques.answer[0].comments}}</div>
                            <div class="operation">
                                <div class="edit" (click)="editComment(ques.answer[0], i)"> <img src="../../../assets/edit-comment.svg" /> </div>
                                <div class="delete" (click)="deleteComment(ques.answer[0])"> <img src="../../../assets/edit-delete.svg" /> </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #addComment>
                        <ng-container *ngIf="(!haveComment && currentCommentIndex === i) || (currentCommentIndex !== i)">
                            <div class="add-comment">
                                <div (click)="addQuestion(i)" class="text">ADD COMMENT</div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="(haveComment && currentCommentIndex === i)">
                            <div class="question-form">
                                <div class="text-input">
                                    <textarea rows="1" placeholder="Add your comment here" [(ngModel)]="comment"></textarea>
                                </div>
                                <div (click)="postQuestion(ques.answer[0])" class="btn">
                                    <button>ADD</button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-template>
                </ng-container>

                <ng-template #student>
                    <ng-container *ngIf="ques.answer[0].comments">
                        <div class="student-comment">
                            <div class="comment-header">Teacher’s Comment</div>
                            <div class="value">{{ques.answer[0].comments}}</div>
                        </div>
                    </ng-container>
                </ng-template>

                <hr>
            </div>
        </div>

        <ng-container *ngIf="theme === 'teacher' ">
            <div class="action-btn">
                <div class="save" (click)="saveReview()">
                    <button mat-flat-button>SAVE REVIEW</button>
                </div>
                <div class="share">
                    <button mat-flat-button [color]="'primary'" [disabled]="!isReviewed" (click)="shareReview()">SHARE WITH STUDENT</button>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

</div>

<div class="test-evaluate-container" *ngIf="width<760">
  <ng-container *ngIf="isLoading; else testEvaluation">
    <app-spinner></app-spinner>
  </ng-container>

  <ng-template #testEvaluation>
    <div class="evaluate-wrapper">
      <div class="header">
        <div class="name">
          <div class="back" (click)="back()"> <img [src]="backButtonSrc" /> </div>
        </div>
        <div class="right">
          <div class="txt">
            <div>CORRECT ANSWERS</div>
            <div class="correct">{{studentTest.correctAnswers}}</div>
          </div>
          <div class="txt">
            <div>INCORRECT ANSWERS</div>
            <div class="incorrect">{{studentTest.incorrectAnswers}}</div>
          </div>
          <div class="txt">
            <div>MARKS OBTAINED</div>
            <div class="marks">{{studentTest.marks}} Marks</div>
          </div>

        </div>
      </div>

      <div class="action" [ngClass]="theme">
        <button mat-icon-button (click)="select.open()">
          <mat-label>ACTIONS</mat-label>
          <mat-select
            #select
            [formControl]="filter"
          >
            <mat-option *ngFor="let action of actions"  [value]="action">{{action}}</mat-option>
          </mat-select>
        </button>
      </div>

      <div class="main">
        <ng-container *ngIf="filter.value">
          <div class="filter">
            <div class="text">Showing:</div>
            <div class="val">{{filter.value}}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="!filteredList.length">
          <div class="no-result">No results Found</div>
        </ng-container>

        <div *ngFor="let ques of filteredList; let i= index" class="questionList">
          <div [ngClass]="{'questionaire': !(ques.answer[0].selectedAnswers && ques.answer[0].selectedAnswers.length)}">
            <div class="ques-header">
              <div class="index">
                <div>Question #{{i+1}}</div>
                <div class="img" *ngIf="(ques.answer[0].selectedAnswers && ques.answer[0].selectedAnswers.length)"> <img [src]="getAnswerStatus(ques) ? './../../assets/info-mark.svg' : './../../assets/red-cross.svg'" /> </div>
              </div>
              <div>{{ques.answer[0].marks}} marks</div>
            </div>

            <div class="name">
              <div>{{ques.question.question}}</div>
            </div>

            <div class="image" *ngIf="ques.question.image">
              <img [src]=ques.question.image />
            </div>

            <div *ngFor="let option of ques.question.options; let i = index" class="option">
              <div class="image" *ngIf="option.image">
                <img [src]=option.image />
              </div>
              <div class="selected-option">
                <div class="correct-option" [ngClass]="{'correct': option.isCorrect}">{{option.option}}</div>
                <div class="attempt" *ngIf="isAnswerCorrect(ques.answer[0], i)"></div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="theme === 'teacher'; else student">
            <ng-container *ngIf="ques.answer[0].comments !== ''; else addComment">
              <div class="comment">
                <div class="val">{{ques.answer[0].comments}}</div>
                <div class="operation">
                  <div class="edit" (click)="editComment(ques.answer[0], i)"> <img src="../../../assets/edit-comment.svg" /> </div>
                  <div class="delete" (click)="deleteComment(ques.answer[0])"> <img src="../../../assets/edit-delete.svg" /> </div>
                </div>
              </div>
            </ng-container>

            <ng-template #addComment>
              <ng-container *ngIf="(!haveComment && currentCommentIndex === i) || (currentCommentIndex !== i)">
                <div class="add-comment">
                  <div (click)="addQuestion(i)" class="text">ADD COMMENT</div>
                </div>
              </ng-container>

              <ng-container *ngIf="(haveComment && currentCommentIndex === i)">
                <div class="question-form">
                  <div class="text-input">
                    <textarea rows="1" placeholder="Add your comment here" [(ngModel)]="comment"></textarea>
                  </div>
                  <div (click)="postQuestion(ques.answer[0])" class="btn">
                    <button>ADD</button>
                  </div>
                </div>
              </ng-container>
            </ng-template>
          </ng-container>

          <ng-template #student>
            <ng-container *ngIf="ques.answer[0].comments">
              <div class="student-comment">
                <div class="comment-header">Teacher’s Comment</div>
                <div class="value">{{ques.answer[0].comments}}</div>
              </div>
            </ng-container>
          </ng-template>

          <hr>
        </div>
      </div>

      <ng-container *ngIf="theme === 'teacher' ">
        <div class="action-btn">
          <div class="save" (click)="saveReview()">
            <button mat-flat-button>SAVE REVIEW</button>
          </div>
          <div class="share">
            <button mat-flat-button [color]="'primary'" [disabled]="!isReviewed" (click)="shareReview()">SHARE WITH STUDENT</button>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

</div>
