<!--  non-mobile Screens-->
<div class="take-test-container" *ngIf="width >= 760">
  <ng-container *ngIf="isLoading; else testDetail">
      <app-spinner></app-spinner>
  </ng-container>
  <ng-template #testDetail>
      <ng-container *ngIf="!isSubmitted; else complete">
          <div class="class-test-wrapper">
              <ng-container *ngIf="test">
                  <div class="header">
                      <div class="left">{{test.title | titlecase}}</div>
                      <div class="right">
                          <div class="maximum">
                              <div class="txt">MAXIMUM MARKS</div>
                              <div class="val">{{test.totalMarks}} Marks</div>
                          </div>
                          <ng-container *ngIf="testStarted">
                              <div class="sub">
                                      <div class="btn">
                                          <button mat-flat-button color="primary" (click)="submitTest()" [disabled]="isSubmitted">SUBMIT</button>
                                      </div>
                              </div>
                          </ng-container>
                      </div>
                  </div>
              </ng-container>

              <ng-container *ngIf="!testStarted; else startTest">
                  <div class="instruction">
                      <div class="txt">INSTRUCTIONS</div>
                      <div class="val">{{instruction}}</div>
                  </div>

                  <div class="buttons">
                      <div class="back">
                          <button mat-flat-button color="primary" (click)="back()">GO BACK</button>
                      </div>
                      <div class="start">
                          <button mat-flat-button color="primary" (click)="start()">START</button>
                      </div>
                  </div>
              </ng-container>

              <ng-template #startTest>
                  <div class="content">
                      <div class="list">
                          <app-question-list-view [questions]="questionList" (questionSelected)="getQuestion($event)" ></app-question-list-view>
                      </div>
                      <div class="question" *ngIf="questionDetail && questionDetail.length > 0">
                          <app-test-question  [selectedQuestion]="questionDetail"></app-test-question>
                      </div>
                  </div>
              </ng-template>
          </div>
      </ng-container>

      <ng-template #complete>
          <div class="complete">
              <div class="great">Great!</div>
              <div class="txt">You finished your test</div>
              <div class="btn">
                  <button mat-flat-button color="primary" (click)="getResult()">SEE MY RESULTS</button>
              </div>
          </div>
      </ng-template>
  </ng-template>
</div>
<!--mobile Screens-->
<div class="take-test-container" *ngIf="width < 760">
  <ng-container *ngIf="isLoading; else testDetail">
    <app-spinner></app-spinner>
  </ng-container>
  <ng-template #testDetail>
    <ng-container *ngIf="!isSubmitted; else complete">
      <div class="class-test-wrapper">
        <ng-container *ngIf="test">
          <div class="header" [class.mat-elevation-z8]="true" (click)="noQues()">
            <div class="txt">Test</div>
          </div>
        </ng-container>

        <ng-container *ngIf="!testStarted; else startTest">
          <div class="sub-header">
            <div class="title" style="word-break: break-all;">Title: {{test.title | titlecase}}</div>
            <div class="sub-title">
              <div class="total">Total: <span class="value">{{test.totalMarks}} marks</span></div>
              <div class="time">Time: <span class="value">{{this.hoursLeft.toString().padStart(2,'0')}}:{{this.minsLeft.toString().padStart(2,'0')}} hours</span></div>
            </div>
          </div>
          <div class="instruction">
            <div class="txt">Instructions</div>
            <div class="val">{{instruction}}</div>
          </div>

          <div class="buttons">
            <div class="back">
              <button mat-flat-button color="primary" (click)="back()">GO BACK</button>
            </div>
            <div class="start">
              <button mat-flat-button color="primary" (click)="start()">START TEST</button>
            </div>
          </div>
        </ng-container>

        <ng-template #startTest>
          <div class="sub-header" (click)="noQues()">
            <div class="title">
              <p class="title-p">Title: {{test.title | titlecase}}</p>
              <p class="submit-p" mat-flat-button color="primary" (click)="submitTest()">SUBMIT</p>
            </div>
            <div class="sub-title">
              <div class="total">Total: <span class="value">{{test.totalMarks}} marks</span></div>
              <div class="time">Time: <span class="value">
                <countdown
                           [config]="{leftTime: leftTime}"
                           (event)="timeUp($event)">$!m!:$!s!

              </countdown> hours</span></div>
            </div>
          </div>
          <div class="content">
            <div class="list">
              <div>
                <app-question-list-view [questions]="questionList" (questionSelected)="getQuestion($event)" ></app-question-list-view>
              </div>
              <div class="action">
                <div class="previous">
                  <button mat-flat-button color="primary" (click)="back()">CANCEL</button>
                </div>

                <div class="next">
                  <button mat-flat-button color="primary" (click)="submitTest()">SUBMIT</button>
                </div>
              </div>
            </div>
           <div class="question" [class.mat-elevation-z8]="true" *ngIf="questionDetail && questionDetail.length > 0 && questionDetail">
             <div class="test-question-wrapper">
              <app-test-question  [selectedQuestion]="questionDetail"></app-test-question>
             </div>
             <div class="action">
               <div class="previous">
                 <button mat-flat-button color="primary" (click)="goToQuestion('previous')">PREVIOUS</button>
               </div>

               <div class="next">
                 <button mat-flat-button color="primary" (click)="goToQuestion('next')">NEXT</button>
               </div>
             </div>
           </div>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <ng-template #complete>
      <div class="complete">
        <div class="great">Great!</div>
        <div class="txt">You finished your test</div>
        <div class="btn">
          <button mat-flat-button color="primary" (click)="getResult()">SEE MY RESULTS</button>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
