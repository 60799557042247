import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileSizeValidationService } from 'src/app/services/file-size-validation.service';
import { UserService } from 'src/app/services/user.service';
import { UploadFromLibraryComponent } from 'src/app/teachers/upload-from-library/upload-from-library.component';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';

@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent implements OnInit {
  docFile = [];
  width = window.innerWidth;
  @Input() type: string;
  @Input() fileNames = [];
  userType: string;
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() uploadFromLibrary: boolean = false;
  @Input() isdocUploaded: boolean;
  @Input() isCourseActivity = false;
  @ViewChild('fileInput') inputFile: ElementRef;
  @Output() docUrl = new EventEmitter();
  duplicateError = false;

  @Input() isEdit = false;


  showDialog: boolean = false;

  fileType: string = 'folder';
  postType: string;

  dialoConfig = {
    width: '363px',
    height: '190px',
    backdropClass: 'blur',
    panelClass: ['delete-dialog-css'],
    autoFocus: false,
    data: null
  };

  userTypes = USER_TYPES;

  constructor(
    public userService: UserService,
    public dialog: MatDialog,
    public fileSizeService: FileSizeValidationService,
  ) { }

  ngOnInit(): void {
    this.userType = this.userService.getUserInCache('type');
    this.postType = this.text.split(' ')[1].toLowerCase();
  }

  getFileSource() {
    this.showDialog = true;
    // let dialogConfig = { ...this.dialoConfig};
    // dialogConfig.data = { type: this.text.split(' ')[1].toLowerCase(), fileType: 'folder' }
    // const dialog = this.dialog.open(InsertFileDialogComponent, dialogConfig);
    // dialog.afterClosed().subscribe(res => {
    //   if(res) {
    //     res === 'library' ? this.fetchFromLibrary() : this.fetchFromLocalDrive();
    //   }
    // })
  }

  fetchFromLibrary() {
    let dialogConfig = { ...this.dialoConfig };
    dialogConfig.width = '90%';
    dialogConfig.height = '73%';
    dialogConfig.data = { isAdminTheme: this.userType === USER_TYPES.admin, userType: this.userType, isCloudContent: this.isCourseActivity };
    const dialog = this.dialog.open(UploadFromLibraryComponent, dialogConfig);
    dialog.afterClosed().subscribe(res => {
      if (res) {
        if (!this.uploadFromLibrary) {
          this.fileNames = [];
        }
        this.duplicateError = false;
        res.map(file => {
          if (!this.fileNames.filter(name => name.id === file.id).length) {
            this.fileNames.push(file)
          } else {
            this.duplicateError = true;
          }
        })
        this.uploadFromLibrary = true;
        this.docUrl.emit({ files: res, uploadFromLibrary: this.uploadFromLibrary });
      }
    })
  }

  fetchFromLocalDrive() {
    this.inputFile.nativeElement.click();
  }

  getFiles() {
    if (this.disabled) {
      return;
    }
    if (this.userType === USER_TYPES.student || this.isEdit) {
      this.fetchFromLocalDrive();
      return;
    }
    this.text.split(' ')[0].toLowerCase() === 'browse' ? this.fetchFromLocalDrive() : this.getFileSource();
  }


  fileChangeEvent(event) {
    this.docFile = [];
    this.duplicateError = false;
    if (event.target.files && event.target.files.length) {
      const file = event.target.files;
      for (let i = 0; i < file.length; i++) {
        if (this.fileSizeService.isValidFile(file[i])) {
          this.docFile.push(file[i]);
        }
      }
      if (this.uploadFromLibrary && !this.isEdit) {
        this.fileNames = [];
      }
      this.duplicateError = false;
      this.docFile.map((file) => {
        if (file && this.fileNames.filter(files => files.name === file.name).length === 0) {
          this.fileNames.push(file);
        } else {
          this.duplicateError = true;
        }
      });
      this.docUrl.emit({ files: this.fileNames, uploadFromLibrary: false });
    }
  }

  fileNameFormat(name) {
    return name.length > 40 ? name.substr(0, 40).concat("...") : name;
  }

  deleteFile(fileName) {
    if (this.disabled) {
      return;
    }
    this.fileNames = this.fileNames.filter((file: any) => file.name !== fileName.name);
    this.duplicateError = false;
    this.docUrl.emit({ files: this.fileNames });
  }

  getFile(): string {
    switch (this.userType) {
      case USER_TYPES.teacher:
        return 'assets/marked-document.svg';
      case USER_TYPES.admin:
        return 'assets/marked-document-admin.svg';
      case USER_TYPES.superAdmin:
        return 'assets/marked-document-super-admin.svg';
      default:
        return 'assets/green-docfile.svg';
    }
  }

  getDeleteImage() {
    switch (this.userType) {
      case USER_TYPES.teacher:
        return 'assets/delete.svg';
      case USER_TYPES.admin:
        return 'assets/admin-delete.svg';
      case USER_TYPES.superAdmin:
        return 'assets/super-admin-delete.svg';
      default:
        return 'assets/green-delete.svg';
    }
  }

  close(res) {
    this.showDialog = false;
    if (res) {
      res === 'library' ? this.fetchFromLibrary() : this.fetchFromLocalDrive();
    }
  }

}
