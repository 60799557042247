<div class="class-detail-container" *ngIf="width>760">
  <ng-container *ngIf="isLoading; else content">
    <app-spinner></app-spinner>
  </ng-container>
  <ng-template #content>
    <div class="table-wrapper">
      <div class="header">
        <div class="back" (click)="back()"><img src="assets/green-back.svg" /></div>
        <div>{{name | titlecase}}</div>
      </div>

      <ng-container>
        <div class="live-class-wrapper">
          <app-student-live-class [data]="liveClassData" [columns]="liveClassColumn"></app-student-live-class>
        </div>
      </ng-container>
      <!-- commenting for demo will be needed later -->
      <!-- <ng-container>
          <div class="checkbox-box line">
            <div class="checkbox">
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [color]="'primary'"
                [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </div>
          </div>
      </ng-container> -->

      <ng-container>
        <div class="dashboard">
          <div class="other-activity">Other Activities</div>
          <div>
            <table mat-table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="desc" matSortDisableClear>
              <ng-container matColumnDef="date">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let transaction"> {{transaction.date | date: formatDate}} </td>
              </ng-container>

              <ng-container matColumnDef="activity">
                <th mat-header-cell *matHeaderCellDef>Activity Type</th>
                <td mat-cell *matCellDef="let transaction">
                  <div class="activity">
                    <div>{{transaction.activityType | titlecase}}</div>
                    <div class="dot"></div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let transaction"> {{transaction.title | titlecase}} </td>
              </ng-container>

              <ng-container matColumnDef="totalmarks">
                <th mat-header-cell *matHeaderCellDef>Total Marks</th>
                <td mat-cell *matCellDef="let transaction">
                  {{transaction.activityType === 'materials' ? '--' : transaction.totalMarks}}</td>
              </ng-container>

              <ng-container matColumnDef="marks">
                <th mat-header-cell *matHeaderCellDef>Marks Obtained</th>
                <td mat-cell *matCellDef="let transaction">
                  {{transaction.activityType === 'materials' ? '--' : transaction.obtainedMarks}}</td>
              </ng-container>

              <ng-container matColumnDef="evaluation">
                <th mat-header-cell *matHeaderCellDef>Evaluation</th>
                <td mat-cell *matCellDef="let transaction" [ngClass]="getEvalClass(transaction)">
                  {{transaction.activityType === 'materials' ? '--' : (transaction.evalStatus | titlecase)}}</td>
              </ng-container>


              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let transaction" [ngClass]="getClass(transaction)">
                  {{transaction.activityType === 'materials' ? '--' : transaction.submissionStatus | titlecase}} </td>
              </ng-container>

              <!-- <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
                  <td mat-cell *matCellDef="let transaction" class="action-table-header" (click)="$event.stopPropagation()">
                      <div class="select">
                          <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
                          <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                              <button mat-menu-item>
            <span>Edit Content</span>
          </button>
                              <button mat-menu-item>
            <span>Delete Content</span>
          </button>
                          </mat-menu>
                      </div>
                  </td>
              </ng-container> -->


              <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>

              <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="sendActivity(row)"></tr>
            </table>
          </div>
        </div>
        <ng-container>
          <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"> </mat-paginator>
        </ng-container>
      </ng-container>
    </div>

  </ng-template>
</div>

<div class="class-detail-container" *ngIf="width<=760">
  <ng-container *ngIf="isLoading; else content">
    <app-spinner></app-spinner>
  </ng-container>
  <ng-template #content>
    <div class="table-wrapper">
      <div class="header">
        <div class="back" (click)="back()"><img src="assets/green-back.svg" /></div>
        <div>{{name | titlecase}}</div>
      </div>
      <!-- commenting for demo will be needed later -->
      <!-- <ng-container>
          <div class="checkbox-box line">
            <div class="checkbox">
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [color]="'primary'"
                [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </div>
          </div>
      </ng-container> -->

      <ng-container>
        <div class="dashboard">
          <div class="other-activity">Other Activities</div>
            <div class="container">
              <div class="box" *ngFor="let children of sortedTransactions" (click)="sendActivity(children)">
                <div class="profile">
                  <div class="details">
                    <div class="name" style="word-break: break-word">{{children.title | titlecase}}</div>
                    <div class="activity-type">Activity: <span class="text">{{children.activityType | titlecase}}</span></div>
                    <div class="date">Date: <span class="text">{{children.date | date: formatDate}}</span></div>
                    <div class="total-marks">Total Marks: <span class="text">{{children.activityType === 'materials' ? '--' : children.totalMarks}}</span></div>
                    <div class="obtained-marks">Obtained Marks: <span class="text">{{children.activityType === 'materials' ? '--' : children.obtainedMarks}}</span></div>
                    <div class="evaluation">Evaluation: <span class="text">{{children.activityType === 'materials' ? '--' : (children.evalStatus | titlecase)}}</span></div>
                    <div class="status">status: <span class="text">{{children.activityType === 'materials' ? '--' : (children.submissionStatus | titlecase)}}</span></div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </ng-container>
    </div>

  </ng-template>
</div>
