import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TestService } from 'src/app/teachers/services/test.service';
import { UserAlertComponent } from '../user-alert/user-alert.component';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';

const ALL_ANSWERS = 'Show all answers'
const CORRECT_ANSWERS = 'Show correct answers'
const INCORRECT_ANSWERS = 'Show incorrect answers'
const UNANSWERED = 'Show unanswered'
const COMMENTS = 'Show teacher comments'

export interface evalutedTestDetailModel {
  answers: [{
    comments: string,
    correctAnswers: number[],
    marks: number,
    questionNumber: number,
    selectedAnswers: number[]
  }],
  classID: string,
  correctAnswers: number,
  id: string,
  incorrectAnswers: number,
  marks: number,
  marksObtained?: number,
  result: string,
  reviewStatus: string,
  studentID: string,
  studentName: string,
  testID: string,
  totalMarks: number,
  unanswered: number
}

export interface EvaluatedTestdialogData {
  testDetails: evalutedTestDetailModel;
  questionList: any;
  isCourseActivity: boolean;
}
@Component({
  selector: 'app-test-evaluate',
  templateUrl: './test-evaluate.component.html',
  styleUrls: ['./test-evaluate.component.scss']
})
export class TestEvaluateComponent implements OnInit {
  actions = [ALL_ANSWERS, INCORRECT_ANSWERS, CORRECT_ANSWERS, UNANSWERED, COMMENTS];
  filter = new FormControl();
  isLoading = false;
  theme: string;
  filteredList = [];
  questionList = [];
  comment: string;
  haveComment = false;
  currentCommentIndex = 0;
  isReviewed = false;
  studentTest: evalutedTestDetailModel;
  backButtonSrc: string;
  width = window.innerWidth;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EvaluatedTestdialogData,
    public dialogRef: MatDialogRef<any>,
    public localstorage: LocalStorageService,
    public dialog: MatDialog,
    public testservice: TestService,
    public snackbar: SnackbarService,
    public location: Location
  ) { }

  ngOnInit(): void {
    if(this.data.isCourseActivity) {
      this.actions = this.actions.filter(action => action !== COMMENTS);
    }
    this.studentTest = this.data.testDetails;
    if(this.studentTest.marksObtained) {
      this.studentTest.marks = this.studentTest.marksObtained;
    }
    this.theme = this.localstorage.getItem('type');
    this.backButtonSrc = this.theme === USER_TYPES.admin ? '../../../assets/grey-back.svg' : '../../../assets/green-back.svg';
    this.isReviewed = this.studentTest.reviewStatus === 'shared' ? true : false;
    this.data.questionList.map(question => {
      const ques = this.studentTest.answers.filter(ans => ans.questionNumber === question.number);
      this.questionList.push({ answer: ques, question: question });
    })
    this.filteredList = this.questionList;

    if (this.theme === 'teacher') {
      this.showAlert();
    }

    this.filter.valueChanges.subscribe((val) => {
      const filterType = val.split(' ');
      if (filterType.includes('correct')) {
        this.filteredList = this.questionList.filter((test: any) => this.getAnswerStatus(test));
      } else if (filterType.includes('incorrect')) {
        this.filteredList = this.questionList.filter((test: any) => {
          if (test.answer[0].selectedAnswers && test.answer[0].selectedAnswers.length) {
            return !this.getAnswerStatus(test)
          }
        });
      } else if (filterType.includes('all')) {
        this.filteredList = this.questionList;
      } else if (filterType.includes('unanswered')) {
        this.filteredList = this.questionList.filter((test: any) => (test.answer[0].selectedAnswers && !test.answer[0].selectedAnswers.length))
      } else {
        this.filteredList = this.questionList.filter((test: any) => test.answer[0].comments !== '')
      }
    })
  }

  showAlert() {
    const dialogRef = this.dialog.open(UserAlertComponent, {
      width: '40%',
    });
  }

  getAnswerStatus(question) {
    const sortedCorrectAnswers = question.answer[0].correctAnswers.sort();
    const sortedSelectedAnswers = question.answer[0].selectedAnswers.sort();
    let isCorrect = true;
    sortedCorrectAnswers.map((answer, index) => {
      if (answer !== sortedSelectedAnswers[index]) {
        isCorrect = false;
      }
    })
    return isCorrect ? true : false;
  }

  isAnswerCorrect(answer, optionIndex) {
    return answer.selectedAnswers.includes(optionIndex)
  }

  addQuestion(index) {
    this.haveComment = true;
    this.currentCommentIndex = index;
  }

  deleteComment(answer) {
    answer.comments = '';
    this.comment = '';
    this.haveComment = false;
  }

  editComment(answer, index) {
    this.comment = answer.comments;
    answer.comments = '';
    this.haveComment = true;
    this.currentCommentIndex = index;
  }

  postQuestion(answer) {
    answer.comments = this.comment;
    this.comment = '';
    this.haveComment = false;
  }

  saveReview() {
    this.isLoading = true;
    let formattedAnswer = [];
    this.questionList.map(question => {
      question.answer.map(ans => {
        formattedAnswer.push({ questionNumber: ans.questionNumber, comments: ans.comments })
      })
    })
    const requestBody = {
      testID: this.studentTest.testID,
      studentID: this.studentTest.studentID,
      answers: formattedAnswer
    }
    this.testservice.addTestComment(requestBody).subscribe(
      (res) => {
        this.isLoading = false;
        this.isReviewed = true;
      },
      (err) => this.snackbar.openSnackbar(err.error.error)
    )
  }

  shareReview() {
    const testID = this.studentTest.testID;
    const studentID = this.studentTest.studentID;
    this.testservice.shareComment(testID, studentID).subscribe(
      (res) => {
        this.dialogRef.close('true');
        this.snackbar.openSnackbar(res.msg);
      },
      (err) => this.snackbar.openSnackbar(err.error.error)
    )
  }

  back() {
    this.dialogRef.close();
  }

}
