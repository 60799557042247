<div class="post-detail-wrapper" *ngIf="width >= 760">
    <div class="header">
        <div class="img" (click)="back()"> <img [src]=getBackIcon() /> </div>
        <div class="text">{{text | titlecase}}</div>
    </div>
    <ng-container *ngIf="theme === 'admin' else others">
        <div class="box">
            <div class="card">
                <div class="title">
                    <div class="txt">Title: {{post.title | titlecase}}</div>
                    <div class="preview admin" (click)="viewPost()"> PREVIEW {{text | uppercase}}</div>
                </div>
                <ng-container>
                    <div class="subheader" *ngIf="post.marks; else material">
                        <div class="txt txt-course-activity">Total Marks : <span class="marks">{{post.totalMarks}}</span></div>
                        <div class="txt">Attachments : <span class="marks">{{post.files.length}}</span></div>
                    </div>
                </ng-container>
                <ng-template #material>
                    <div class="subheader">
                        <div class="txt txt-course-activity">Attachments: <span class="marks">{{post.files.length}}</span></div>
                    </div>
                </ng-template>
                <div class="note">{{post.description }}</div>
            </div>
        </div>
    </ng-container>
    <ng-template #others>
        <ng-container *ngIf="theme === 'teacher'; else student">
            <div class="box">
                <div class="card">
                    <div class="title">
                        <div class="txt">Title: {{post.title | titlecase}}</div>
                        <div class="preview teacher" (click)="viewPost()"> PREVIEW {{text | uppercase}}</div>
                    </div>
                    <ng-container *ngIf="post.marks; else material">
                        <div class="subheader">
                            <div>Date of Submission : <span class="date">{{post.submissionDate | date: dateFormat}}</span></div>
                            <div class="txt">Total Marks : <span class="marks">{{post.marks}}</span></div>
                            <div class="txt">Attachments : <span class="marks">{{post.attachment}}</span></div>
                        </div>
                    </ng-container>
                    <ng-template #material>
                        <div class="subheader">
                            <div>Due Date of Reading : <span class="date">{{post.dueDate | date: dateFormat}}</span></div>
                            <div class="txt">Attachments: <span class="marks">{{post.attachment}}</span></div>
                        </div>
                    </ng-template>

                    <div class="note">{{post.description }}</div>
                </div>
            </div>
        </ng-container>

        <ng-template #student>
            <div class="box">
                <div class="card">
                    <div class="title">
                        <div class="txt">Title: {{post.title}}</div>
                        <div class="preview student" (click)="viewPost()"> VIEW {{text | uppercase}}</div>
                    </div>
                    <ng-container *ngIf="text.toLowerCase() !== 'material'; else material">
                        <div class="subheader">
                            <div *ngIf="!isCourseActivity">Date of Submission : <span class="date">{{post.submissionDate | date: dateFormat}}</span></div>
                            <div [ngClass]="isCourseActivity ? 'txt-course-activity' : ''" class="txt">Status :<span class="status"> {{post.assignmentStatus | titlecase}}</span></div>
                        </div>
                    </ng-container>

                    <ng-template #material>
                        <div class="subheader">
                            <div *ngIf="!isCourseActivity">Due Date of Reading: <span class="date">{{post.dueDate | date: dateFormat}}</span></div>
                            <div [ngClass]="isCourseActivity ? 'txt-course-activity' : ''" class="txt">Status :<span class="status"> {{post.materialStatus === 'pending' ? 'New' : 'Read'}}</span></div>
                        </div>
                    </ng-template>

                    <div class="note">{{post.description}}</div>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>

<div class="post-detail-wrapper" *ngIf="width < 760">

  <ng-container *ngIf="theme === 'admin' else others">
    <div class="box">
      <div class="card">
        <div class="title">
          <div class="txt">Title: {{post.title | titlecase}}</div>
          <div class="preview admin" (click)="viewPost()"> PREVIEW {{text | uppercase}}</div>
        </div>
        <ng-container>
          <div class="subheader" *ngIf="post.marks; else material">
            <div class="txt txt-course-activity">Total Marks : <span class="marks">{{post.totalMarks}}</span></div>
            <div class="txt">Attachments : <span class="marks">{{post.files.length}}</span></div>
          </div>
        </ng-container>
        <ng-template #material>
          <div class="subheader">
            <div class="txt txt-course-activity">Attachments: <span class="marks">{{post.files.length}}</span></div>
          </div>
        </ng-template>
        <div class="note">{{post.description }}</div>
      </div>
    </div>
  </ng-container>
  <ng-template #others>
    <ng-container *ngIf="theme === 'teacher'; else student">
      <div class="box">
        <div class="card">
          <div class="title">
            <div class="txt">Title: {{post.title | titlecase}}</div>
            <div class="preview teacher" (click)="viewPost()"> PREVIEW {{text | uppercase}}</div>
          </div>
          <ng-container *ngIf="post.marks; else material">
            <div class="subheader">
              <div>Date of Submission : <span class="date">{{post.submissionDate | date: dateFormat}}</span></div>
              <div class="txt">Total Marks : <span class="marks">{{post.marks}}</span></div>
              <div class="txt">Attachments : <span class="marks">{{post.attachment}}</span></div>
            </div>
          </ng-container>
          <ng-template #material>
            <div class="subheader">
              <div>Due Date of Reading : <span class="date">{{post.dueDate | date: dateFormat}}</span></div>
              <div class="txt">Attachments: <span class="marks">{{post.attachment}}</span></div>
            </div>
          </ng-template>

          <div class="note">{{post.description }}</div>
        </div>
      </div>
    </ng-container>

    <ng-template #student>
      <div class="title">
        <div class="txt">Title: {{post.title}}</div>
      </div>
      <div class="box">
        <div class="card">

          <ng-container *ngIf="text.toLowerCase() !== 'material'; else material">
            <div class="subheader">
              <div *ngIf="!isCourseActivity" class="submission-date"><span class="submission-txt">Date of Submission</span> <span class="date">{{post.submissionDate | date: dateFormat}}</span></div>
              <div [ngClass]="text !== 'material' ? 'txt-total-marks' : ''" class="total-marks"><span class="total-marks-txt">Total Marks</span><span class="marks"> {{post.totalMarks}}</span></div>
              <div class="txt"><span class="status-txt">Status</span><span class="status"> {{post.assignmentStatus | titlecase}}</span></div>
            </div>
          </ng-container>

          <ng-template #material>
            <div class="subheader">
              <div *ngIf="!isCourseActivity">Due Date of Reading <br> <span class="date">{{post.dueDate | date: dateFormat}}</span></div>
              <div [ngClass]="isCourseActivity ? 'txt-course-activity' : ''" class="txt">Status <span class="status"> {{post.materialStatus === 'pending' ? 'New' : 'Read'}}</span></div>
            </div>
          </ng-template>

          <div class="note">{{post.description}}</div>
<!--          <button mat-flat-button color="#39bda7" (click)="viewPost()"> VIEW {{text | uppercase}}</button>-->
          <button (click)="viewPost()">VIEW {{text | uppercase}}</button>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
