<div class="profile-container" *ngIf="width >= 760">
  <ng-container *ngIf="isLoading; else profile">
    <app-spinner></app-spinner>
  </ng-container>
  <ng-template #profile>
    <div class="profile-wrapper" [ngClass]="{'student-class' : theme === 'student'}">
      <div class="text">Profile</div>

      <div class="section bordered-box">
        <div class="photo">
          <div class="logo">
            <img [src]="user.profilePicture" />
          </div>
        </div>

        <div>
          <ng-container>
            <div class="text">
              <div class="header">Name:</div>
              <div class="value1">{{user.name | titlecase}}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="user.gender">
            <div class="text">
              <div class="header">Gender:</div>
              <div class="value1">{{user.gender | titlecase}}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="user.email">
            <div class="text">
              <div class="header">Email:</div>
              <div class="value1">{{user.email }}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="user.username">
            <div class="text">
              <div class="header">User Name:</div>
              <div class="value1">{{user.username}}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="user.pin">
            <div class="text">
              <div class="header">PIN:</div>
              <div class="value1">****</div>
            </div>
          </ng-container>

          <!-- <ng-container *ngIf="theme === 'student'">
              <div class="text">
                  <div class="header">PIN:</div>
                  <div class="value1">****</div>
                  <div class="change-pin-text" (click)="changePin()">CHANGE PIN</div>
              </div>
          </ng-container> -->

        </div>

        <ng-container *ngIf="theme !== 'student'; else student">
          <div class="grp-btn">
            <div class="edit-profile button-top-margin">
              <button mat-flat-button color="primary" (click)="edit()">EDIT PROFILE</button>
            </div>
            <div class="switch full-flex button-top-margin">
              <button mat-flat-button color="primary" (click)="switchProfile(theme)">SWITCH SCHOOL GROUP</button>
            </div>
            <div class="logout full-flex button-top-margin" [ngClass]="theme">
              <button mat-flat-button (click)="showAlertMsg()">LOGOUT</button>
            </div>
          </div>
        </ng-container>

        <ng-template #student>
          <div class="action">
            <div class="switch button-top-margin">
              <button mat-flat-button color="primary" (click)="edit()">EDIT PROFILE</button>
            </div>
            <!-- <div class="switch">
                <button mat-flat-button color="primary" (click)="switchProfile(theme)">SWITCH PROFILE</button>
            </div> -->
            <div class="switch logout button-top-margin">
              <button mat-flat-button (click)="showAlertMsg()">LOGOUT</button>
            </div>
          </div>
        </ng-template>
      </div>

    </div>
  </ng-template>
</div>

<div class="profile-container" *ngIf="width < 760">
  <ng-container *ngIf="isLoading; else profile">
    <app-spinner></app-spinner>
  </ng-container>
  <ng-template #profile>
    <div class="profile-wrapper" [ngClass]="{'student-class' : theme === 'student'}">
      <div class="photo">
        <div class="logo">
          <img [src]="user.profilePicture" />
        </div>
      </div>


      <div class="section bordered-box">

        <div>
          <ng-container>
            <div class="text">
              <div class="header">Name:</div>
              <div class="value1" style="word-break: break-word">{{user.name | titlecase}}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="user.gender">
            <div class="text">
              <div class="header">Gender:</div>
              <div class="value1">{{user.gender | titlecase}}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="user.email">
            <div class="text">
              <div class="header">Email:</div>
              <div class="value1" style="word-break: break-word">{{user.email }}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="user.username">
            <div class="text">
              <div class="header">User Name:</div>
              <div class="value1" style="word-break: break-word">{{user.username}}</div>
            </div>
          </ng-container>

          <ng-container *ngIf="user.pin">
            <div class="text">
              <div class="header">PIN:</div>
              <div class="value1">****</div>
            </div>
          </ng-container>

          <!-- <ng-container *ngIf="theme === 'student'">
              <div class="text">
                  <div class="header">PIN:</div>
                  <div class="value1">****</div>
                  <div class="change-pin-text" (click)="changePin()">CHANGE PIN</div>
              </div>
          </ng-container> -->

        </div>

        <ng-container *ngIf="theme !== 'student'; else student">
          <div class="grp-btn">
            <div class="edit-profile button-top-margin">
              <button mat-flat-button color="primary" (click)="edit()">EDIT PROFILE</button>
            </div>
            <div class="switch full-flex button-top-margin">
              <button mat-flat-button color="primary" (click)="switchProfile(theme)">SWITCH SCHOOL GROUP</button>
            </div>
            <div class="logout full-flex button-top-margin" [ngClass]="theme">
              <button mat-flat-button (click)="showAlertMsg()">LOGOUT</button>
            </div>
          </div>
        </ng-container>

        <ng-template #student>
          <div class="action">
            <div class="switch button-top-margin">
              <button mat-flat-button color="primary" (click)="edit()">EDIT PROFILE</button>
            </div>
            <!-- <div class="switch">
                <button mat-flat-button color="primary" (click)="switchProfile(theme)">SWITCH PROFILE</button>
            </div> -->
            <div class="switch logout button-top-margin">
              <button mat-flat-button (click)="showAlertMsg()">LOGOUT</button>
            </div>
          </div>
        </ng-template>
      </div>

    </div>
  </ng-template>
</div>
