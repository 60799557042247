import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { MatDialog } from '@angular/material/dialog';
import { TestEvaluateComponent } from 'src/app/shared/test-evaluate/test-evaluate.component';
import { StudentTestService } from '../../services/student-test.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { dateFormat, COURSE_ACTIVITY_ROUTE } from 'src/app/Constants/Constant';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { zip, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { PreviewComponent } from 'src/app/teachers/preview/preview.component';
import { StudentCourseActivitiesService } from '../services/student-course-activities.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-test-result',
  templateUrl: './test-result.component.html',
  styleUrls: ['./test-result.component.scss']
})
export class TestResultComponent implements OnInit, OnDestroy {
  dateFormat: string;
  test;
  testResult;
  solutionUrl: string[];
  questionList = [];
  isLoading = true;
  isCourseActivity = false;

  private isdestroy$$: Subject<any> = new Subject<any>();
  constructor(
    public sidenav: ToggleSidenavService,
    public testService: StudentTestService,
    public snackbar: SnackbarService,
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public localstorage: LocalStorageService,
    public dialog: MatDialog,
    private courseActivitiesSvc: StudentCourseActivitiesService,
  ) { }

  ngOnInit(): void {
    this.dateFormat = dateFormat;
    this.sidenav.minmizeSidenav();
    const testID = this.route.snapshot.params.id;
    const studentID = this.localstorage.getItem('studentID');
    this.isCourseActivity = this.router.url.includes(COURSE_ACTIVITY_ROUTE);
    if (this.isCourseActivity) {
      this.courseActivitiesSvc.getCourseActivity(testID, studentID);
      this.courseActivitiesSvc.selectedCourseActivity$.pipe(
        takeUntil(this.isdestroy$$)
      ).subscribe(courseActivityDetails => {
        if (courseActivityDetails) {
          this.test = courseActivityDetails.activity;
          this.fetchQuestions(courseActivityDetails.activityURLs[0]);
          this.solutionUrl = courseActivityDetails.activityURLs[0];
          this.testResult = courseActivityDetails.submission;
          this.isLoading = false;
          console.log(this.testResult);
        }
      });

    } else {
      const getTest = this.testService.getTest(testID);
      const getTestResult = this.testService.getResult(testID, studentID);
      zip(getTest, getTestResult).subscribe(
        (res) => {
          this.isLoading = false;
          this.test = res[0].test;
          this.fetchQuestions(res[0].questionURL);
          this.solutionUrl = res[0].solutionURLs;
          this.testResult = res[1];
          console.log(this.testResult);
        },
        (err) => {
          this.isLoading = false;
          this.snackbar.openSnackbar('Unable to fetch the test details!!')
        }
      );
    }
  }

  fetchQuestions(questionURL) {
    this.testService.getQuestionFromGcs(questionURL).subscribe(
      (res) => {
        res = JSON.parse(res);
        res.map(question => {
          if (!question.instruction) {
            this.questionList.push(question)
          }
        })
        this.questionList.map((question, index) => {
          question.options.map((option, i) => {
            if (this.testResult.answers[index].correctAnswers.includes(i)) {
              question.options[i].isCorrect = true;
            } else {
              question.options[i].isCorrect = false;
            }
          })
        })
      },
      (err) => {
        this.snackbar.openSnackbar('Unable to fetch the test details')
      }
    );
  }

  goBack() {
    if (this.isCourseActivity) {
      this.router.navigate(['../../../../', this.test.courseID], { relativeTo: this.route });
      return;
    }
    this.router.navigate(['../../../../', 'class-details', this.test.classID], { relativeTo: this.route });
  }

  viewEvaluation() {
    const dialog = this.dialog.open(TestEvaluateComponent, {

      height: '100%',
      data: { testDetails: this.testResult, questionList: this.questionList, isCourseActivity: this.isCourseActivity },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['student-theme', 'full-width', 'test-evaluation-dialog'],
      autoFocus: false
    })
  }

  previewTest() {
    const dialogRef = this.dialog.open(PreviewComponent, {
      width: '100%',
      height: '100%',
      data: { question: this.questionList, testDetail: this.test },
      panelClass: ['teacher-theme', 'width-class'],
      autoFocus: false
    });
  }

  ngOnDestroy() {
    this.courseActivitiesSvc.reset();
  }
}
