import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LabelService } from './services/label.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'shiksha';
  hide = true;
  constructor(private router: Router, private labelS: LabelService, updates: SwUpdate) {
    this.labelS.getLabels();
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      this.hide = true;
    }else{
      // false for not mobile device
      this.hide = false;
    }

    updates.available.subscribe(event => {
      updates.activateUpdate().then(() => document.location.reload());
    });
  }
}
