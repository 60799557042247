import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownModule } from 'ngx-countdown';
import { ChildDashboardRoutingModule } from './child-dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClassDetailComponent } from './class-detail/class-detail.component';
import { MaterialModule } from 'src/app/material/material.module';
import { NoticeBoardComponent } from './notice-board/notice-board.component';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { ClassPostComponent } from './class-post/class-post.component';
import { TakeTestComponent } from './take-test/take-test.component';
import { TestQuestionComponent } from './test-question/test-question.component';
import { QuestionListViewComponent } from './question-list-view/question-list-view.component';
import { TestResultComponent } from './test-result/test-result.component';
import { GetEvaluationComponent } from './get-evaluation/get-evaluation.component';
import { StudentLiveClassComponent } from './student-live-class/student-live-class.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CourseListComponent } from './courses-list/course-list.component';
import { ChildCourseDetailComponent } from './child-course-detail/child-course-detail.component';
import { ChildWorkComponent } from './child-work/child-work.component';
import { MyWorkCardComponent } from './my-work-card/my-work-card.component';
import { CompletedWorkComponent } from './completed-work/completed-work.component';
import { SharedComponentsModule } from 'src/app/shared-components/shared-components.module';


@NgModule({
  declarations: [
    DashboardComponent,
    HomeComponent,
    ClassDetailComponent,
    NoticeBoardComponent,
    ClassPostComponent,
    TakeTestComponent,
    TestQuestionComponent,
    QuestionListViewComponent,
    TestResultComponent,
    GetEvaluationComponent,
    StudentLiveClassComponent,
    CourseListComponent,
    ChildCourseDetailComponent,
    ChildWorkComponent,
    MyWorkCardComponent,
    CompletedWorkComponent],
  imports: [
    CommonModule,
    CountdownModule,
    SharedModule,
    ChildDashboardRoutingModule,
    MaterialModule,
    CdkScrollableModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule
  ]
})
export class ChildDashboardModule { }
