import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { allowedImageType, allowedVideoType } from 'src/app/Constants/Constant';
import { GetEvaluationComponent } from 'src/app/students/child-dashboard/get-evaluation/get-evaluation.component';
import { docsPreviewList } from 'src/app/teachers/post-details/post-details.component';
import { ViewDocsComponent } from '../view-docs/view-docs.component';
@Component({
  selector: 'app-view-attachment',
  templateUrl: './view-attachment.component.html',
  styleUrls: ['./view-attachment.component.scss']
})
export class ViewAttachmentComponent implements OnInit {
  videoFile: docsPreviewList[] = [];
  imageFile: docsPreviewList[] = [];
  docFile: docsPreviewList[] = [];
  width = window.innerWidth;
  @Input() files: string[];
  @Input() urls: string[];
  @Input() type = 'assignment';
  @Input() isEvaluated = false;
  @Input() metadata = [];
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.files && this.files.map((file, index) => {
      const extension = file.split('.');
      if (allowedImageType.includes(extension[extension.length - 1])) {
        this.addImage(file, index);
      } else if (allowedVideoType.includes(extension[extension.length - 1])) {
        this.addVideo(file, index);
      } else {
        this.addDoc(file, index);
      }
    });
  }

  addImage(file, index) {
    this.imageFile.push({ name: file, signedUrl: this.urls[index]});
  }

  addVideo(file, index) {
    this.videoFile.push({ name: file, signedUrl: this.urls[index]});
  }

  addDoc(file, index) {
    this.docFile.push({ name: file, signedUrl: this.urls[index]});
  }

  viewDocs(type) {
    const filesArray = [];
    if (type === 'video') {
      this.videoFile.length > 0 ? this.videoFile.map(file => filesArray.push(file)) : null;
      this.imageFile.length > 0 ?  this.imageFile.map(file => filesArray.push(file)) : null;
      this.docFile.length > 0 ?  this.docFile.map(file => filesArray.push(file)) : null;
    } else if (type === 'image') {
      this.imageFile.length > 0 ?  this.imageFile.map(file => filesArray.push(file)) : null;
      this.videoFile.length > 0 ? this.videoFile.map(file => filesArray.push(file)) : null;
      this.docFile.length > 0 ?  this.docFile.map(file => filesArray.push(file)) : null;
    } else {
      this.docFile.length > 0 ?  this.docFile.map(file => filesArray.push(file)) : null;
      this.imageFile.length > 0 ?  this.imageFile.map(file => filesArray.push(file)) : null;
      this.videoFile.length > 0 ? this.videoFile.map(file => filesArray.push(file)) : null;
    }

    if (this.isEvaluated) {
      const dialog = this.dialog.open(GetEvaluationComponent, {
        width: '95%',
        height: '100%',
        data: { files: filesArray, metadata: this.metadata },
        backdropClass: 'blur',
        disableClose: true ,
        panelClass: ['student-theme', 'full-width'],
        autoFocus: false
      });
    } else {
      if (window.innerWidth >= 760) {
        const dialog = this.dialog.open(ViewDocsComponent, {
          width: '95%',
          height: '100%',
          data: { files: filesArray, type: this.type.toLowerCase()},
          backdropClass: 'blur',
          disableClose: true ,
          panelClass: ['student-theme', 'full-width', 'preview-doc-container', 'custom-dialog-container'],
          autoFocus: false
        });
      } else {
        const dialog = this.dialog.open(ViewDocsComponent, {
          width: '100%',
          height: '100%',
          data: { files: filesArray, type: this.type.toLowerCase()},
          backdropClass: 'blur',
          disableClose: true ,
          panelClass: ['student-theme', 'full-width', 'preview-doc-container', 'custom-dialog-container'],
          autoFocus: false
        });
      }
    }
  }
}
