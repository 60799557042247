import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';
import { dateFormat } from 'src/app/Constants/Constant';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetailComponent implements OnInit {
  @Input() post;
  @Input() text;
  @Output() onPreview = new EventEmitter();
  @Input() isCourseActivity = false;
  theme : string;
  dateFormat: string;
  width = window.innerWidth;

  constructor(public uesrService: UserService, private _location: Location) { }

  ngOnInit(): void {
    console.log(this.post);
    this.dateFormat = dateFormat;
    this.theme = this.uesrService.getUserInCache('type');
  }

  back() {
    this._location.back();
  }

  viewPost() {
    this.onPreview.emit();
  }

  getBackIcon() {
    return this.theme === 'teacher' ? '../../../assets/blue-back.svg' : this.theme === 'admin' ?'../../../assets/grey-back.svg' : '../../../assets/green-back.svg';
  }

}
