<div class="container" [ngClass]="theme" *ngIf="width >= 760">
    <div class="left bordered-box" [ngStyle]="{'background-color': themeColor}">
        <app-user-info [userType]="storeUserType"></app-user-info>
    </div>
    <ng-container *ngIf="!signUp">
        <div class="right">
            <div *ngIf="storeUserType !== userTypes.superAdmin && (isOtp || isPin) || (storeUserType === userTypes.student && loginMode)" class="back" (click)="back()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path fill="#607D8B" [ngStyle]="{'fill': themeColor}" d="M15.013 6.758c-.065-.01-.132-.015-.198-.014H3.547l.245-.115c.24-.113.459-.268.646-.457l3.16-3.16c.416-.397.486-1.036.166-1.514-.373-.51-1.088-.62-1.598-.247-.04.03-.08.063-.117.098L.335 7.064c-.446.446-.447 1.17 0 1.616l5.714 5.715c.447.446 1.171.445 1.617-.002.035-.035.068-.073.098-.112.32-.478.25-1.117-.166-1.515L4.444 9.601c-.168-.168-.36-.309-.572-.417l-.343-.155h11.223c.584.022 1.096-.386 1.206-.96.101-.623-.322-1.21-.945-1.31z"/>
            </svg>
            </div>
            <div class="txt">Get Started</div>
            <ng-container *ngIf="storeUserType !== userTypes.superAdmin else superAdmin">
                <app-otp-login
                  *ngIf="(storeUserType === userTypes.student && loginMode) || storeUserType !== userTypes.student"
                  [theme]="storeUserType" [isOtp]="isOtp"
                  [isNumberError]="isNumberError" [isOTPError]="isOTPError"
                  [otpError]="otpError" [otp]="otp" [pin]="pin"
                  [otpTimer]="otpTimer" [hasError]="hasError"
                  (phoneNumberInput)="enterPhoneNumber($event)"
                  (loginApp)="login()"
                  (verifyApp)="verify($event)" (sendOTP)="sendOtp()"
                  (inputChange)="onChange($event)" [isPin]="isPin"
                  (pinLoginApp)="choosePinLogin()" (verifyPin)="verifyPin()"
                  (pinChange)="changePin($event)" [isPinError]="isPinError"
                  [pinError]="pinError"
                  [loginMode]="loginMode"></app-otp-login>
                <ng-container *ngIf="storeUserType === userTypes.student && !loginMode else otherUsers">
                    <div class="btn">
                        <button *ngIf="!isPin" mat-flat-button color="primary" (click)="selectOtpMode()" [disabled]="isSubmitted">LOG IN WITH OTP</button>
                        <p *ngIf="!isPin && !isOtp">OR</p>
                        <button *ngIf="!isOtp" mat-flat-button color="primary" (click)="selectPinMode()" [disabled]="isSubmitted">LOG IN WITH PIN</button>
                    </div>
                </ng-container>
                <ng-template #otherUsers>
                    <div class="btn">
                        <button mat-flat-button color="primary" (click)="isOtp || isPin ? verifyUser(otp) : loginUser()" [disabled]="isSubmitted">CONTINUE</button>
                    </div>
                </ng-template>


            </ng-container>
            <ng-template #superAdmin>
                <app-email-login [loginForm]="loginForm"></app-email-login>
                <div class="btn">
                    <button mat-flat-button color="primary" (click)="superAdminLogin()" [disabled]="isSubmitted">LOGIN</button>
                </div>
            </ng-template>
        </div>
    </ng-container>

    <ng-container *ngIf="signUp">
        <div class="right">
            <app-profile-details [userType]="storeUserType" [mobileNo]="phoneNumber" (done)="onProfileCreate($event)"></app-profile-details>
        </div>
    </ng-container>
</div>
<div class="container" [ngClass]="theme" *ngIf="width < 760" [ngStyle]="{'background-color': themeColor}">
  <div class="left bordered-box">
    <div class="user-info-wrapper">
      <div class="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="103" height="26" viewBox="0 0 103 26">
          <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
            <text fill="#FFF" font-family="HugMeTight, Hug Me Tight" font-size="18">
              <tspan x="32" y="18">shiksha</tspan>
            </text>
            <circle cx="12" cy="12" r="12" stroke="#FFF" stroke-width="2" />
          </g>
        </svg>
      </div>
      <div class="container">
        <div class="text">{{userName}} </div>
        <div *ngFor="let responsibility of responsibilities" class="tasks">
          <div class="tick">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
              <path fill="#FFF" d="M13.302.905c.472-.44 1.277-1.183 2-.798 1.86.99-.5 3.301-1.25 4.236-1.194 1.513-2.444 2.971-3.666 4.456-.944 1.128-1.777 2.366-2.805 3.466-.583.633-1.582 1.788-2.554 1.733-.806-.027-1.5-.605-2.027-1.155C1.75 11.55.056 9.515 0 7.617c-.027-1.458 1.306-1.018 2.11-.413 1.112.853 1.973 1.898 2.972 2.86.445-.825 1.167-1.54 1.833-2.2 1.166-1.183 2.222-2.393 3.36-3.603 1.028-1.1 1.944-2.31 3.027-3.356zM2.24 9.873l.271.312c.622.698 1.175 1.205 1.907 1.81.563.084.796.214 1.03.343C4.203 10.733 3.134 9.91 2.24 9.873zm12.634-7.76C13.046 2.997 8.223 7.968 8 8.833L10.094 7c1.001-.543 1.45-1.509 2.3-2.183.2-.533 2.306-2.503 2.48-2.704z"/>
            </svg>
          </div>
          <div class="value">{{responsibility}}</div>
        </div>
      </div>

      <ng-container *ngIf="!signUp">
        <div class="right">
          <div class="txt">
            <div *ngIf="storeUserType !== userTypes.superAdmin && (isOtp || isPin) || (storeUserType === userTypes.student && loginMode)" class="back" (click)="back()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fill="#607D8B" [ngStyle]="{'fill': 'white'}" d="M15.013 6.758c-.065-.01-.132-.015-.198-.014H3.547l.245-.115c.24-.113.459-.268.646-.457l3.16-3.16c.416-.397.486-1.036.166-1.514-.373-.51-1.088-.62-1.598-.247-.04.03-.08.063-.117.098L.335 7.064c-.446.446-.447 1.17 0 1.616l5.714 5.715c.447.446 1.171.445 1.617-.002.035-.035.068-.073.098-.112.32-.478.25-1.117-.166-1.515L4.444 9.601c-.168-.168-.36-.309-.572-.417l-.343-.155h11.223c.584.022 1.096-.386 1.206-.96.101-.623-.322-1.21-.945-1.31z"/>
              </svg>
            </div>
            <p class="p-txt">Get Started</p>
          </div>
          <ng-container *ngIf="storeUserType !== userTypes.superAdmin else superAdmin">
            <app-otp-login
              *ngIf="(storeUserType === userTypes.student && loginMode) || storeUserType !== userTypes.student"
              [theme]="storeUserType" [isOtp]="isOtp"
              [isNumberError]="isNumberError" [isOTPError]="isOTPError"
              [otpError]="otpError" [otp]="otp" [pin]="pin"
              [otpTimer]="otpTimer" [hasError]="hasError"
              (phoneNumberInput)="enterPhoneNumber($event)"
              (loginApp)="login()"
              (verifyApp)="verify($event)" (sendOTP)="sendOtp()"
              (inputChange)="onChange($event)" [isPin]="isPin"
              (pinLoginApp)="choosePinLogin()" (verifyPin)="verifyPin()"
              (pinChange)="changePin($event)" [isPinError]="isPinError"
              [pinError]="pinError"
              [loginMode]="loginMode"></app-otp-login>
            <ng-container *ngIf="storeUserType === userTypes.student && !loginMode else otherUsers">
              <div class="btn">
                <button *ngIf="!isPin" mat-flat-button color="primary" (click)="selectOtpMode()" [disabled]="isSubmitted" [ngStyle]="{'color': themeColor}">LOG IN WITH OTP</button>
                <p *ngIf="!isPin && !isOtp">OR</p>
                <button *ngIf="!isOtp" mat-flat-button color="primary" (click)="selectPinMode()" [disabled]="isSubmitted" [ngStyle]="{'color': themeColor}">LOG IN WITH PIN</button>
              </div>
            </ng-container>
            <ng-template #otherUsers>
              <div class="btn">
                <button mat-flat-button color="primary" (click)="isOtp || isPin ? verifyUser(otp) : loginUser()" [disabled]="isSubmitted" [ngStyle]="{'color': themeColor}">CONTINUE</button>
              </div>
            </ng-template>


          </ng-container>
          <ng-template #superAdmin>
            <app-email-login [loginForm]="loginForm"></app-email-login>
            <div class="btn">
              <button mat-flat-button color="primary" (click)="superAdminLogin()" [ngStyle]="{'color': themeColor}" [disabled]="isSubmitted">LOGIN</button>
            </div>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngIf="signUp">
        <div class="right">
          <app-profile-details [userType]="storeUserType" [mobileNo]="phoneNumber" (done)="onProfileCreate($event)"></app-profile-details>
        </div>
      </ng-container>

      <div class="footer teacher" *ngIf="storeUserType === userTypes.teacher">
        <img src="../../assets/teacher-icon.svg" />
      </div>

      <div class="footer admin" *ngIf="storeUserType === userTypes.admin || storeUserType === userTypes.superAdmin">
        <img src="../../assets/admin-icon.svg" />
      </div>

      <div class="footer parent" *ngIf="storeUserType === userTypes.student">
        <img src="../../assets/student-icon.svg" />
      </div>
    </div>
  </div>

</div>

