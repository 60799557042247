<ng-container *ngIf="type === 'course'; else others">
    <div class="card course-card">
        <div *ngFor="let course of lists" class="list course-card-container">
            <div class="course">
                <div class="img"> <img [src]=course.bannerImage /></div>
                <div class="title">{{course.name}}</div>
                <div *ngIf="course.subscribed" class="subscribe-check">
                    <button mat-icon-button>
                    <mat-icon [style]="{ 'color': '#39bda7' }">check_circle</mat-icon>
                    </button>
                </div>
            </div>
          <div class="progress-bar-container-wrapper" *ngIf="width >= 760">
            <div [ngClass]="course.subscribed ? 'colored': ''" class="progress-bar-container">
                <div *ngIf="course.subscribed" class="percentage">
                    <p>{{ course.completionPercentage | number: '1.0-2'}}%</p>
                </div>
                <div *ngIf="course.subscribed" class="progress-bar">
                    <mat-progress-bar *ngIf="course.subscribed" mode="determinate" [value]="course.completionPercentage"></mat-progress-bar>
                </div>
            </div>
          </div>
          <div class="subscribe-button" (click)="onCardClick(course)" *ngIf="width >= 760">
            <button [disabled]="updatingState">{{ course.subscribed ? 'GO TO COURSE' : 'SUBSCRIBE'}}</button>
          </div>
          <div class="progress-bar-container-wrapper" *ngIf="width < 760">
            <div [ngClass]="course.subscribed ? 'colored': ''" class="progress-bar-container">
              <div class="completed-text">
                <p>Completed</p>
              </div>
              <div *ngIf="course.subscribed" class="progress-bar">
                <mat-progress-bar *ngIf="course.subscribed" mode="determinate" [value]="course.completionPercentage"></mat-progress-bar>
              </div>
              <div *ngIf="course.subscribed" class="percentage">
                <p>{{ course.completionPercentage | number: '1.0-2'}}%</p>
              </div>
            </div>
            <div class="subscribe-button" (click)="onCardClick(course)" *ngIf="width < 760">
              <button [disabled]="updatingState">{{ course.subscribed ? 'GO TO COURSE' : 'SUBSCRIBE'}}</button>
            </div>
          </div>
        </div>
    </div>
</ng-container>
<ng-template #others>
    <ng-container *ngIf="type === 'school'; else class">
        <div class="card">
            <div *ngFor="let school of lists" class="list">
                <div class="school" (click)="onCardClick(school)">
                    <div class="img"> <img [src]="school.logo ? school.logo : 'assets/school.svg'" /></div>
                    <div class="name">{{school.name}}</div>
                    <div class="select">
                        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="select($event)">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                            <!-- <button mat-menu-item>
                                <span>Edit Profile</span>
                            </button> -->
                            <button mat-menu-item [disabled]="!school.isCreator" (click)="archiveSchool(school.id,school.status)">
                            <span>{{school.status == 'active' ? 'Archive' : 'Unarchive'}}</span>
                        </button>
                        </mat-menu>
                    </div>
                </div>

                <div class="role" (click)="onCardClick(school)">
                    <div>Role:</div>
                    <div class="value">{{school.role}}</div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #class>
        <div class="class-card">
            <div *ngFor="let class of lists" class="list">
                <div class="class" (click)="onCardClick(class)">
                    <div class="name">
                        <span>{{class.name | titlecase}},</span> <span class="setting">{{class.standard}},</span> <span class="setting">{{class.section | titlecase}}</span>
                    </div>
                    <div class="select">
                        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="select($event)">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                            <button mat-menu-item (click)="archiveClass(class.id,class.status)">
                                <span>{{(class.status == 'archived' || class.schoolStatus == 'archived') ? 'Unarchive' : 'Archive'}}</span>
                            </button>
                            <button mat-menu-item [disabled]="class.schoolStatus == 'archived'" (click)="editClass(class)">
                                <span>Edit</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>

                <div class="detail" (click)="onCardClick(class)">
                    <div class="name">
                        <div> <img src="../../../assets/class-students.svg" /> </div>
                        <div class="text">{{class.totalStudents}}</div>
                        <div class="label"> {{labelData['students']}} </div>
                    </div>
                    <div class="sub">
                        <div> <img src="../../../assets/book.svg" /> </div>
                        <div class="text">{{class.subject | titlecase}}</div>
                    </div>
                </div>

                <div class="role" (click)="onCardClick(class)">
                    <div class="performance">
                        <div>{{labelData['school']}} : </div>
                        <div class="text">{{class.school | titlecase}}</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>
