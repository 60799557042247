import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SchoolService } from 'src/app/services/api/school.service';
import { TeacherService } from 'src/app/services/api/teacher.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ClassSpecificService } from 'src/app/teachers/services/class-specific.service';
import { AlertDialogComponent } from '../../shared-components/alert-dialog/alert-dialog.component';
import { UNARCHIVED_STATUS, ARCHIVED_STATUS } from 'src/app/Constants/Constant';
import { SchoolSpecificSettingsService } from 'src/app/teachers/services/school-specific-settings.service';
import { EditClassComponent } from 'src/app/teachers/edit-class/edit-class.component';
import { LabelService } from 'src/app/services/label.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit,OnDestroy {
  @Input() lists;
  @Input() type;
  @Input() updatingState;
  @Output() selectedlist = new EventEmitter();
  @Output() getClasses = new EventEmitter();
  labelData;
  private destroy$$: Subject<any> = new Subject<any>();
  constructor(
    private classSpecificService: ClassSpecificService,
    private classService: SchoolSpecificSettingsService,
    private snackbar: SnackbarService,
    private teacherService: TeacherService,
    public dialogRef: MatDialog,
    private schoolService: SchoolService,
    private dialog: MatDialog,
    private labelService: LabelService
  ) { }
  width = window.innerWidth;

  ngOnInit(): void {
    this.labelService.labels$.pipe(takeUntil(this.destroy$$)).subscribe(data => this.labelData = data)
    console.log(this.lists)
  }

  onCardClick(list) {
    this.selectedlist.emit(list);
  }

  select(event) {
    event.stopPropagation();
  }
  archiveClass(id, status) {
    let statusMessage: string;
    if (status == UNARCHIVED_STATUS) {
      statusMessage = "archived"
      status = ARCHIVED_STATUS;
    } else {
      statusMessage = "unarchived"
      status = UNARCHIVED_STATUS;
    }
    this.classSpecificService.changeClassStatus(id, status).subscribe(
      (res) => {
        this.snackbar.openSnackbar(`Class ${statusMessage} successfully`);
        if (status == ARCHIVED_STATUS) {
          this.teacherService.archiveClass(id);
        } else {
          this.teacherService.unArchiveClass(id);
        }
      },
      (err) => {
        if (err.error.error.includes('Access restricted')) {
          const dialogRef = this.dialogRef.open(AlertDialogComponent, {
            width: '363px',
            height: '190px',
            backdropClass: 'blur',
            data: { alertText: 'Access Denied', type: "accessDeniedHome" },
            panelClass: ['delete-dialog-css'],
            autoFocus: false
          });
        }
        else {
          this.snackbar.openSnackbar(err.error.error)
        }
      }
    )
  }
  archiveSchool(id, statusVal) {
    let status = statusVal;
    let statusMessage: string;
    if (status == UNARCHIVED_STATUS) {
      statusMessage = "archived"
      status = ARCHIVED_STATUS;
    } else {
      statusMessage = "unarchived"
      status = UNARCHIVED_STATUS;
    }
    this.schoolService.changeSchoolStatus(id, status).subscribe(
      (res) => {
        this.snackbar.openSnackbar(`School ${statusMessage} successfully`);
        if (status === ARCHIVED_STATUS) {
          this.schoolService.archiveSchool(id);
        } else {
          this.schoolService.unArchiveSchool(id);
        }
      },
      (err) => {
        if (err.error.error.includes('Access restricted')) {
          const dialogRef = this.dialogRef.open(AlertDialogComponent, {
            width: '363px',
            height: '190px',
            backdropClass: 'blur',
            data: { alertText: 'Access Denied'},
            panelClass: ['delete-dialog-css'],
            autoFocus: false
          })
        }
        else {
          this.snackbar.openSnackbar(err.error.error)
        }
      }
    )
  }
  editClass(classDetail){
    this.classService.getSchools().subscribe(
      (resp) => {
        const dialogRef = this.dialog.open(EditClassComponent, {
          width: '50%',
          height: '100%',
          data : { schools: resp.schools, classDetail: classDetail },
          backdropClass: 'blur',
          disableClose: true ,
          panelClass: ['teacher-theme', 'width-class'],
          autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.teacherService.getClasses();
          }
        })
      },
      (err) => { this.snackbar.openSnackbar(err.error.error)}
    )
  }
  ngOnDestroy(){
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }
}
